const { default: systemConfig } = require('src/config/system-config');
const { isLoggedIn, getAccessToken } = require('./auth');

const fetcher = (accessToken) => {
  const _fetch = (method, endpoint, body, successCallback, errorCallback) => {
    let response;

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    let url;

    if (endpoint.includes('v2')) {
      url = systemConfig.apiUrl.replace('v1', 'v2') + endpoint.replace('/v2', '');
    } else {
      url = systemConfig.apiUrl + endpoint;
    }

    body = JSON.stringify(body);
    if (isLoggedIn() || accessToken) headers.Authorization = `Bearer ${accessToken || getAccessToken()}`;
    if (body === undefined) {
      response = fetch(url, { method, headers });
    } else {
      response = fetch(url, { method, headers, body });
    }

    handleResponse(response, successCallback, errorCallback);
  };

  const _fetchFormData = (method, endpoint, body, successCallback, errorCallback) => {
    let response;

    const headers = {
      Accept: 'application/json',
    };
    const url = systemConfig.apiUrl + endpoint;

    if (isLoggedIn() || accessToken) headers.Authorization = `bearer ${accessToken || getAccessToken()}`;

    if (body === undefined) {
      console.error('Body is required');
    } else {
      response = fetch(url, { method, headers, body });
    }

    handleResponse(response, successCallback, errorCallback);
  };

  const handleResponse = (response, successCallback, errorCallback) => {
    response.then((res) => {
      if (res.status === 200) {
        res.json().then((result) => {
          if (result.success) {
            if (successCallback) {
              successCallback(result.data);
            }
          } else if (errorCallback) {
            errorCallback(result.error);
          }
        });
      } else if (res.status === 403) {
        if (errorCallback) errorCallback('No permission - 403');
      } else if (res.status === 401) {
        if (errorCallback) errorCallback('Unauthorized - 401');
        //  logout
        localStorage.removeItem(systemConfig.accessTokenKey);
      } else if (res.status === 500) {
        if (errorCallback) errorCallback('Internal Server Error - 500');
      } else if (res.status === 502) {
        if (errorCallback) errorCallback('Service Unavailable - 502');
      } else if (res.status === 526) {
        if (errorCallback) errorCallback('Please connect to VPN - 526');
      } else if (errorCallback) errorCallback('Unknown');
    });
  };

  return {
    get: (endpoint, successCallback, errorCallback) => _fetch('GET', endpoint, undefined, successCallback, errorCallback),
    post: (endpoint, body, successCallback, errorCallback) => _fetch('POST', endpoint, body, successCallback, errorCallback),
    postFormData: (endpoints, body, successCallback, errorCallback) => _fetchFormData('POST', endpoints, body, successCallback, errorCallback),
    put: (endpoint, body, successCallback, errorCallback) => _fetch('PUT', endpoint, body, successCallback, errorCallback),
    delete: (endpoint, body, successCallback, errorCallback) => _fetch('DELETE', endpoint, body, successCallback, errorCallback),
  };
};

export default fetcher;

//

export const endpoints = {
  auth: {
    profile: '/admin/profile',
    login: '/admin/login',
    ga: '/admin/ga',
    password: '/admin/password',
  },
  dashboard: {
    overview: '/dashboard/overview',
    revenueChart: '/dashboard/revenue-chart',
    user: '/user',
    userList: '/user/list',
    userMembership: '/membership/list',
    nft: '/nft-product',
    nftList: '/nft/list',
    nftProductList: '/nft-product/list',
    nftStatus: '/nft/status',
    nftLog: '/log/nft',
    revenueLog: '/log/revenue',
    adminUser: '/admin-user',
    adminUserRole: '/admin-user/role',
    adminUserList: '/admin-user/list',
    adminRoleList: '/admin-role/list',
    adminUserPassword: '/admin-user/password',
    adminRole: '/admin-role',
    systemSetting: '/system/settings',
    tokenSetting: '/system/token-settings',
    walletTxs: '/wallet/get-funding-transactions',
    walletApproveWithdraw: '/wallet/approve-withdraw',
    walletCancelWithdraw: '/wallet/withdraw',
    walletLog: '/wallet/logs',
    walletBalance: '/wallet/balances',
    affiliate: '/affiliate',
    affiliateSunTree: '/affiliate/get-sun-tree',
    walletComBalance: '/com-wallet/balances',
    walletComLog: '/com-wallet/logs',
    walletHardUpdateWithdraw: '/wallet/hard-update-withdraw',
    walletHardUpdateBalance: '/wallet/hard-update-balance',
    walletScanDeposit: '/wallet/scan-deposits',
    walletSubmitDeposit: '/wallet/submit-deposits',
    comWalletHardUpdateBalance: '/com-wallet/hard-update-balance',
    walletComTsx: '/com-wallet/get-com-transactions',
  },
  config: {
    enums: '/config/enums',
    tokens: '/config/tokens',
  },
};
