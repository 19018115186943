import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { RequiredAuth } from 'src/auth/utils';
import { LoadingScreen } from 'src/components/loading-screen';
import { scopeConfig } from 'src/config/config-global';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
// USER
const UserListPage = lazy(() => import('src/pages/dashboard/user/UserList'));
const UserDetailPage = lazy(() => import('src/pages/dashboard/user/UserDetails'));
const MembershipPage = lazy(() => import('src/pages/dashboard/user/Membership'));
// NFT
const NFTProductList = lazy(() => import('src/pages/dashboard/nft/NFTProductList'));
const NFTList = lazy(() => import('src/pages/dashboard/nft/NFTList'));
const NFTImportPage = lazy(() => import('src/pages/dashboard/nft/Import'));

const StakingProductsPage = lazy(() => import('src/pages/dashboard/staking/StakingProductsPage'));
const StakingListPage = lazy(() => import('src/pages/dashboard/staking/StakingListPage'));

const GiftCardProductsPage = lazy(() => import('src/pages/dashboard/gift-card/GiftCardProductsPage'));
const GiftCardListPage = lazy(() => import('src/pages/dashboard/gift-card/GiftCardListPage'));

// ADMIN_USER
const AdminAccountPage = lazy(() => import('src/pages/dashboard/admin-user/AdminAccount'));
const AdminRolePage = lazy(() => import('src/pages/dashboard/admin-user/AdminRole'));
// SYSTEM SETTING
const GeneralSettingPage = lazy(() => import('src/pages/dashboard/system/SystemSettings'));
const TokenSettingPage = lazy(() => import('src/pages/dashboard/system/Tokens'));
// COMMAND
const UpdateBalancePage = lazy(() => import('src/pages/dashboard/command/UpdateBalance'));
const ScanDepositPage = lazy(() => import('src/pages/dashboard/command/ScanDeposit'));
const LogsPage = lazy(() => import('src/pages/dashboard/command/Logs'));
// WALLET
const WalletDepositPage = lazy(() => import('src/pages/dashboard/wallet/WalletDeposit'));
const WalletWithdrawPage = lazy(() => import('src/pages/dashboard/wallet/WalletWithdraw'));
const WalletComPage = lazy(() => import('src/pages/dashboard/wallet/Commissions'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

const BinaryPage = lazy(() => import('src/pages/dashboard/command/Binary'));

const WalkupOpenTicketsPage = lazy(() => import('src/pages/dashboard/support/WalkupOpenTicketsPage'));
const NFTMemberOpenTicketsPage = lazy(() => import('src/pages/dashboard/support/NFTMemberOpenTicketsPage'));
const WalkupAllTicketsPage = lazy(() => import('src/pages/dashboard/support/WalkupAllTicketsPage'));
const NFTMemberAllTicketsPage = lazy(() => import('src/pages/dashboard/support/NFTMemberAllTicketsPage'));

const NotificationListPage = lazy(() => import('src/pages/dashboard/notification/notification-list'));

const TokenHolderListPage = lazy(() => import('src/pages/dashboard/token-holder/token-holder-list'));
const TokenHolderSettingsPage = lazy(() => import('src/pages/dashboard/token-holder/token-holder-settings'));

const ProductPaymentCardsPage = lazy(() => import('src/pages/dashboard/payment-card/product-payment-cards'));
const UserPurchasedPaymentCardsPage = lazy(() => import('src/pages/dashboard/payment-card/user-purchased-payment-cards'));
const PaymentCardComTransactionsPage = lazy(() => import('src/pages/dashboard/payment-card/payment-card-com-transactions'));

const PaymentCardCreatePage = lazy(() => import('src/pages/dashboard/payment-card/PaymentCardNew'));
const PaymentCardEditPage = lazy(() => import('src/pages/dashboard/payment-card/PaymentCardEdit'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'user',
        children: [
          { element: <Navigate to="list" />, index: true },
          { path: 'list', element: <RequiredAuth element={<UserListPage />} validScopes={scopeConfig.userRead} /> },
          { path: 'membership', element: <RequiredAuth element={<MembershipPage />} validScopes={scopeConfig.userRead} /> },
        ],
      },
      {
        path: 'notification',
        children: [
          { element: <Navigate to="list" />, index: true },
          { path: 'list', element: <RequiredAuth element={<NotificationListPage />} validScopes={scopeConfig.supportFull} /> },
        ],
      },
      {
        path: 'token-holder',
        children: [
          { element: <Navigate to="list" />, index: true },
          { path: 'list', element: <RequiredAuth element={<TokenHolderListPage />} validScopes={scopeConfig.tokenHolder} /> },
          { path: 'settings', element: <RequiredAuth element={<TokenHolderSettingsPage />} validScopes={scopeConfig.tokenHolder} /> },
        ],
      },
      {
        path: 'payment-card',
        children: [
          { element: <Navigate to="product/list" />, index: true },
          {
            path: 'product',
            children: [{ path: 'list', element: <RequiredAuth element={<ProductPaymentCardsPage />} validScopes={scopeConfig.paymentCard} /> }],
          },
          {
            path: 'new',
            element: <RequiredAuth element={<PaymentCardCreatePage />} validScopes={scopeConfig.paymentCard} />,
          },
          {
            path: ':id/edit',
            element: <RequiredAuth element={<PaymentCardEditPage />} validScopes={scopeConfig.paymentCard} />,
          },
          {
            path: 'user-purchased',
            children: [{ path: 'list', element: <RequiredAuth element={<UserPurchasedPaymentCardsPage />} validScopes={scopeConfig.paymentCard} /> }],
          },
          {
            path: 'com-transaction',
            children: [
              { path: 'list', element: <RequiredAuth element={<PaymentCardComTransactionsPage />} validScopes={scopeConfig.paymentCard} /> },
            ],
          },
        ],
      },
      { path: 'user-details/:id', element: <RequiredAuth element={<UserDetailPage />} validScopes={scopeConfig.userRead} /> },
      { path: 'nft-product/list', element: <RequiredAuth element={<NFTProductList />} validScopes={scopeConfig.nftRead} /> },
      { path: 'nft/list', element: <RequiredAuth element={<NFTList />} validScopes={scopeConfig.nftRead} /> },
      { path: 'nft/import', element: <RequiredAuth element={<NFTImportPage />} validScopes={scopeConfig.nftWrite} /> },
      { path: 'staking/product/list', element: <RequiredAuth element={<StakingProductsPage />} validScopes={scopeConfig.stakingFull} /> },
      { path: 'staking/list', element: <RequiredAuth element={<StakingListPage />} validScopes={scopeConfig.stakingFull} /> },
      { path: 'gift-card/product/list', element: <RequiredAuth element={<GiftCardProductsPage />} validScopes={scopeConfig.giftCardFull} /> },
      { path: 'gift-card/list', element: <RequiredAuth element={<GiftCardListPage />} validScopes={scopeConfig.giftCardFull} /> },
      { path: 'admin/account', element: <RequiredAuth element={<AdminAccountPage />} validScopes={scopeConfig.adminFull} /> },
      { path: 'admin/role', element: <RequiredAuth element={<AdminRolePage />} validScopes={scopeConfig.adminFull} /> },
      { path: 'system/settings', element: <RequiredAuth element={<GeneralSettingPage />} validScopes={scopeConfig.systemFull} /> },
      { path: 'system/tokens', element: <RequiredAuth element={<TokenSettingPage />} validScopes={scopeConfig.systemFull} /> },
      { path: 'funding/deposit', element: <RequiredAuth element={<WalletDepositPage />} validScopes={scopeConfig.fundingTxsRead} /> },
      { path: 'funding/withdraw', element: <RequiredAuth element={<WalletWithdrawPage />} validScopes={scopeConfig.fundingTxsRead} /> },
      { path: 'funding/com', element: <RequiredAuth element={<WalletComPage />} validScopes={scopeConfig.fundingTxsRead} /> },
      { path: 'command/update-balance', element: <RequiredAuth element={<UpdateBalancePage />} validScopes={scopeConfig.walletHardUpdate} /> },
      { path: 'command/scan-deposit', element: <RequiredAuth element={<ScanDepositPage />} validScopes={scopeConfig.fundingTxsWrite} /> },
      { path: 'command/logs', element: <RequiredAuth element={<LogsPage />} validScopes={scopeConfig.systemFull} /> },

      { path: 'command/binary', element: <RequiredAuth element={<BinaryPage />} validScopes={scopeConfig.systemFull} /> },

      { path: 'blank', element: <BlankPage /> },

      {
        path: 'support',
        children: [
          { element: <Navigate to="all-tickets" />, index: true },
          { path: 'open-tickets', element: <RequiredAuth element={<WalkupOpenTicketsPage />} validScopes={scopeConfig.supportFull} /> },
          { path: 'open-tickets-nftmember', element: <RequiredAuth element={<NFTMemberOpenTicketsPage />} validScopes={scopeConfig.supportFull} /> },
          { path: 'all-tickets', element: <RequiredAuth element={<WalkupAllTicketsPage />} validScopes={scopeConfig.supportFull} /> },
          { path: 'all-tickets-nftmember', element: <RequiredAuth element={<NFTMemberAllTicketsPage />} validScopes={scopeConfig.supportFull} /> },
        ],
      },
    ],
  },
];
