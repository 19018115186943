import PropTypes from 'prop-types';
import { createContext, useCallback, useMemo, useReducer } from 'react';
import fetcher, { endpoints } from 'src/utils/fetcher';

const initialState = {
  config: {},
  tokens: []
};

const reducer = (state, action) => {
  if (action.type === 'FETCH_CONFIG') {
    return {
      ...state,
      config: action.payload,
    };
  }
  if (action.type === 'FETCH_TOKEN') {
    return {
      ...state,
      tokens: action.payload,
    };
  }
  return state;
};

export const ConfigContext = createContext(null);
ConfigProvider.propTypes = {
  children: PropTypes.node,
};
export function ConfigProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const getConfig = useCallback(() => {
    fetcher().get(endpoints.config.enums, (data) => {
      dispatch({
        type: 'FETCH_CONFIG',
        payload: data,
      });
    });
  }, []);
  const getTokens = useCallback(() => {
    fetcher().get(endpoints.config.tokens, (data) => {
      dispatch({
        type: 'FETCH_TOKEN',
        payload: data,
      });
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      config: state.config,
      getConfig,
      tokens: state.tokens,
      getTokens
    }),
    [state.config, state.tokens, getConfig, getTokens]
  );

  return <ConfigContext.Provider value={memoizedValue}>{children}</ConfigContext.Provider>;
}
