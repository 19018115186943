const ProductionConfig = {
  environment: 'production',
  apiUrl: '/api/v1',
  apiV2Url: '/api/v2',
};

const StagingConfig = {
  environment: 'staging',
  apiUrl: 'https://walkup.1fit.fun/admin-api/v1',
  apiV2Url: 'https://walkup.1fit.fun/admin-api/v2',
};

const stagingDomain = 'walkupadmin.1fit.fun';
const isProduction = window.location.hostname.indexOf(stagingDomain) < 0 && window.location.hostname.indexOf('localhost') < 0;

const systemConfig = {
  ...(isProduction ? ProductionConfig : StagingConfig),
  accessTokenKey: 'UYGysaodsaH',
};

export default systemConfig;
