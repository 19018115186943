// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'

//

export const PAGE_SIZE = [10, 20, 40, 50, 80, 100];

export const scopeConfig = {
  userFull: ['USER_WRITE', 'USER_READ'],
  userRead: ['USER_READ'],
  userWrite: ['USER_WRITE'],
  systemFull: ['SYSTEM_FULL'],
  adminFull: ['ADMIN_FULL'],
  nftRead: ['NFT_READ'],
  nftWrite: ['NFT_WRITE'],
  nftFull: ['NFT_READ', 'NFT_WRITE'],
  walletRead: ['WALLET_READ'],
  walletHardUpdate: ['WALLET_HARD_UPDATE'],
  fundingTxsRead: ['FUNDING_TRANSACTION_READ'],
  fundingTxsWrite: ['FUNDING_TRANSACTION_WRITE'],
  fundingTxsHardUpdate: ['FUNDING_TRANSACTION_HARD_UPDATE'],
  fundingTxsFull: ['FUNDING_TRANSACTION_READ', 'FUNDING_TRANSACTION_WRITE'],
  stakingFull: ['STAKING_READ', 'STAKING_WRITE'],
  stakingWrite: ['STAKING_WRITE'],
  stakingRead: ['STAKING_READ'],
  giftCardFull: ['GIFTCARD_FULL'],
  supportFull: ['SUPPORT_FULL'],
  tokenHolder: ['TOKEN_HOLDER'],
  paymentCard: ['PAYMENT_CARD'],
};
// export const NFT_TYPE = ['RUNNER', 'WALKER', 'TRAINER']
// export const RARITY = ['COMMON', 'RARE', 'TRIAL']
// export const SALE_CURRENCIES = ['MATIC', 'WKW', 'WKE']
