import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

ScopeValidate.propTypes = {
  validScopes: PropTypes.array,
  children: PropTypes.node,
};

export function ScopeValidate({ validScopes = [], children }) {
  const { user } = useAuthContext();
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (user) {
      const { scopes } = user
      let temp = false;
      validScopes.forEach((scope) => {
        if (scopes?.includes(scope)) temp = true;
      });
      setValid(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, validScopes]);
  if (validScopes.includes('ALL')) return children;
  if (valid) return children;
  return null;
}
