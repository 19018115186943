import { useContext } from 'react';
import { ConfigContext } from './ConfigContext';
//
// import { AuthContext } from './Auth0Context';
// import { AuthContext } from './FirebaseContext';
// import { AuthContext } from './AwsCognitoContext';

// ----------------------------------------------------------------------

export const useConfigContext = () => {
  const context = useContext(ConfigContext);

  if (!context) throw new Error('useConfigContext context must be use inside ConfigContext');

  return context;
};
