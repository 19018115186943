import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import systemConfig from 'src/config/system-config';
import { getAccessToken } from 'src/utils/auth';
import fetcher, { endpoints } from 'src/utils/fetcher';
import { AuthContext } from './auth-context';

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = getAccessToken();

      if (accessToken) {
        fetcher().get(
          endpoints.auth.profile,
          (user) => {
            dispatch({
              type: 'INITIAL',
              payload: { user },
            });
          },
          () => {
            dispatch({
              type: 'INITIAL',
              payload: {
                user: null,
              },
            });
          }
        );
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (values, successCallback, errorCallback) => {
    const params = {
      email: values.email,
      password: values.password,
      gaCode: values.gaCode,
    };

    fetcher().post(
      endpoints.auth.login,
      params,
      (data) => {
        fetcher(data.accessToken).get(endpoints.auth.profile, (user) => {
          if (successCallback) successCallback({ ...data, profile: user });
          dispatch({
            type: 'LOGIN',
            payload: {
              user,
            },
          });
        });
      },
      (err) => {
        if (errorCallback) errorCallback(err);
      }
    );
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    localStorage.removeItem(systemConfig.accessTokenKey);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user && getAccessToken() ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      logout,
    }),
    [login, logout, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
