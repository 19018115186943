import { useMemo } from 'react';
import SvgColor from 'src/components/svg-color';
import { scopeConfig } from 'src/config/config-global';
import { useLocales } from 'src/locales';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  membership: icon('ic_membership'),
  deposit: icon('ic_deposit'),
  withdraw: icon('ic_withdraw'),
  com: icon('ic_invoice'),
  nft: icon('ic_nft'),
  scan: icon('ic_scan'),
  setting: icon('ic_setting'),
  system: icon('ic_system'),
  binary: icon('ic_binary'),

  notificationList: icon('ic_notification_list'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      {
        subheader: t('overview'),
        items: [
          {
            title: 'dashboard',
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
          },
        ],
      },

      {
        subheader: t('user'),
        scopes: scopeConfig.userFull,
        items: [
          {
            title: t('users'),
            path: paths.dashboard.user.list,
            icon: ICONS.user,
          },
          {
            title: t('memberships'),
            path: paths.dashboard.user.membership,
            icon: ICONS.membership,
          },
        ],
      },
      {
        subheader: t('funding'),
        scopes: scopeConfig.fundingTxsFull,
        items: [
          {
            title: t('deposits'),
            path: paths.dashboard.wallet.deposit,
            icon: ICONS.deposit,
          },
          {
            title: t('withdrawals'),
            path: paths.dashboard.wallet.withdraw,
            icon: ICONS.withdraw,
          },
          {
            title: t('commissions'),
            path: paths.dashboard.wallet.com,
            icon: ICONS.com,
          },
        ],
      },
      {
        subheader: t('NFT'),
        scopes: scopeConfig.nftFull,
        items: [
          {
            title: t('Products'),
            path: paths.dashboard.nft.productList,
            icon: ICONS.product,
          },
          {
            title: t('NFTs'),
            path: paths.dashboard.nft.list,
            icon: ICONS.nft,
          },
          {
            title: t('Import'),
            path: paths.dashboard.nft.import,
            icon: ICONS.nft,
          },
        ],
      },
      {
        subheader: t('Staking'),
        scopes: scopeConfig.stakingFull,
        items: [
          {
            title: t('Products'),
            path: paths.dashboard.staking.products,
            icon: ICONS.product,
          },
          {
            title: t('Staking List'),
            path: paths.dashboard.staking.list,
            icon: ICONS.nft,
          },
        ],
      },
      {
        subheader: t('Gift Card'),
        scopes: scopeConfig.giftCardFull,
        items: [
          {
            title: t('Products'),
            path: paths.dashboard.giftCard.products,
            icon: ICONS.product,
          },
          {
            title: t('Gift Card List'),
            path: paths.dashboard.giftCard.list,
            icon: ICONS.nft,
          },
        ],
      },
      {
        subheader: t('Payment Card'),
        scopes: scopeConfig.paymentCard,
        items: [
          {
            title: t('Products'),
            path: paths.dashboard.paymentCard.products,
            icon: ICONS.product,
          },
          {
            title: t('Card List'),
            path: paths.dashboard.paymentCard.userPurchasedList,
            icon: ICONS.nft,
          },
          {
            title: t('Com Transactions'),
            path: paths.dashboard.paymentCard.comTransactions,
            icon: ICONS.nft,
          },
        ],
      },
      {
        subheader: t('Support - WALKUP'),
        scopes: scopeConfig.supportFull,
        items: [
          {
            title: t('Open Tickets'),
            path: paths.dashboard.support.openTickets,
            icon: ICONS.product,
          },
          {
            title: t('All Tickets'),
            path: paths.dashboard.support.allTickets,
            icon: ICONS.product,
          },
        ],
      },
      {
        subheader: t('Support - NFT MEMBER'),
        scopes: scopeConfig.supportFull,
        items: [
          {
            title: t('Open Tickets'),
            path: paths.dashboard.support.openTicketsNFTMember,
            icon: ICONS.product,
          },
          {
            title: t('All Tickets'),
            path: paths.dashboard.support.allTicketsNFTMember,
            icon: ICONS.product,
          },
        ],
      },
      {
        subheader: t('Token Holders'),
        scopes: scopeConfig.tokenHolder,
        items: [
          {
            title: t('List'),
            path: paths.dashboard.tokenHolder.list,
            icon: ICONS.product,
          },
          {
            title: t('Settings'),
            path: paths.dashboard.tokenHolder.settings,
            icon: ICONS.product,
          },
        ],
      },
      {
        subheader: t('Notification'),
        scopes: scopeConfig.supportFull,
        items: [
          {
            title: t('Notifications'),
            path: paths.dashboard.notification.list,
            icon: ICONS.notificationList,
          },
        ],
      },
      {
        subheader: t('settings'),
        scopes: scopeConfig.systemFull,
        items: [
          {
            title: t('tokens'),
            path: paths.dashboard.system.token,
            icon: ICONS.calendar,
          },
          {
            title: t('system'),
            path: paths.dashboard.system.generalSettings,
            icon: ICONS.setting,
          },
        ],
      },
      {
        subheader: t('administrators'),
        scopes: scopeConfig.adminFull,
        items: [
          {
            title: t('accounts'),
            path: paths.dashboard.admin.account,
            icon: ICONS.user,
          },
          {
            title: t('roles'),
            path: paths.dashboard.admin.role,
            icon: ICONS.lock,
          },
        ],
      },
      {
        subheader: t('command'),
        scopes: [...scopeConfig.systemFull, ...scopeConfig.walletHardUpdate, ...scopeConfig.fundingTxsWrite],
        items: [
          {
            title: t('Logs'),
            path: paths.dashboard.command.logs,
            icon: ICONS.calendar,
            scopes: scopeConfig.systemFull,
          },
          {
            title: t('Update Balance'),
            path: paths.dashboard.command.updateBalance,
            icon: ICONS.system,
            scopes: scopeConfig.walletHardUpdate,
          },
          {
            title: t('Scan Deposit'),
            path: paths.dashboard.command.scanDeposit,
            icon: ICONS.scan,
            scopes: scopeConfig.fundingTxsWrite,
          },
          {
            title: t('Binary'),
            path: paths.dashboard.command.binary,
            icon: ICONS.binary,
            scopes: scopeConfig.systemFull,
          },
        ],
      },
    ],
    [t]
  );

  return data;
}
