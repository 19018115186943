import { Icon } from '@iconify/react';
import { Avatar, Box, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useAuthContext } from 'src/auth/hooks';
import { useRouter } from 'src/routes/hooks';

// ----------------------------------------------------------------------

export default function Account() {
  const { user } = useAuthContext();
  const { logout } = useAuthContext();
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const handleLogout = async () => {
    try {
      await logout();
      router.replace('/');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };
  return (
    <>
      {user ? (
        <Stack direction="row" alignItems="center">
          <Box>
            <Avatar src='/assets/images/avatar.jpg' alt="avatar" />
          </Box>
          <Stack ml={2}>
            <Typography variant="body2" color="text.primary">
              {user?.email}
            </Typography>
            <Typography variant="body2" fontWeight={700} color="text.secondary">
              {user?.role}
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <AccountEmpty />
      )}
    </>
  );
}


const AccountEmpty = () => (
  <Stack direction="row" alignItems="center">
    <Box>
      <Skeleton variant="circular" width={40} height={40} />
    </Box>
    <Stack ml={2} width={145}>
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
    </Stack>
  </Stack>
);
