import { format, formatDistanceToNow, getTime } from 'date-fns';
import moment from 'moment';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  if (date === null || date === 0) return '_ _ : _ _ : _ _';
  const fm = newFormat || 'yyyy/MM/dd p';
  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function formatDateTime(date, timeZone = undefined) {
  if (date === null || date === 0) return '_ _ : _ _ : _ _';
  if (timeZone !== undefined) {
    return moment
      .utc(date)
      .utcOffset(timeZone || 0)
      .format('DD/MM/YYYY HH:mm');
  }
  return moment(date).format('DD/MM/YYYY HH:mm');
}
export function formatDate(date, timeZone = undefined) {
  if (date === null || date === 0) return '_ _ : _ _ : _ _';
  if (timeZone !== undefined) {
    return moment
      .utc(date)
      .utcOffset(timeZone || 0)
      .format('DD/MM/YYYY');
  }
  return moment(date).format('DD/MM/YYYY');
}

export const formatDateToUTCUnix = (date) => {
  if (date === null || date === 0) return 0;
  return (moment.utc(date).unix() + (new Date().getTimezoneOffset() / -60) * 3600) * 1000;
};
