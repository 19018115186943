import { Navigate, useRoutes } from 'react-router-dom';
import { paths } from '../paths';
import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={paths.dashboard.root} replace />,
    },
    ...authRoutes,
    ...dashboardRoutes,
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
